import client from '@/apis/http-client'
import Vue from 'vue'
let pathUrl = `/por/api`

const pathOrgUrl = `sso/rbac/organization/permission`

// Initial state.

const defaultState = () => {
  return {
    contentRepeaterObject: {
      id: null,
      department_id: null,
      contents: [
        {
          code: 'th',
          title: null,
          description: ''
        },
        {
          code: 'en',
          title: null,
          description: ''
        }
      ]
    },
    isLoading: false,
    isEditMode: false,
    deleteId: 0,
    isDelete: false,
    contentRepeaterList: []
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  async create({commit}, cpt) {
    commit('SET_IS_LOADING', true)

    try {
      let create = await client.secureHttpClient.post(
        `${pathUrl}/content-repeater/`,
        cpt
      )
      commit('SET_IS_LOADING', false)
      return create.data.success
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async update({commit}, payload) {
    const {cpt, id} = payload
    commit('SET_IS_LOADING', true)
    try {
      let updated = await client.secureHttpClient.put(
        `${pathUrl}/content-repeater/${id}`,
        cpt
      )
      commit('SET_IS_LOADING', false)
      return updated.data.success
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async deleteContentRepeater({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.delete(
        `${pathUrl}/content-repeater/${state.deleteId}`
      )
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_ID', 0)
      dispatch('fetch')
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async setOrgInfo({commit}, isViewOnly) {
    let mode = ''
    if (isViewOnly) {
      mode = 'View'
    } else if (state.isEditMode) {
      mode = 'Edit'
    } else {
      mode = 'Add'
    }

    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=${mode}&category=Package`
    )
    commit('SET_ORG_LIST', data.data)
  },
  setDepartmentId({commit}, id) {
    commit('SET_DEPARTMENT_ID', id)
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  async setContentRepeater({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(
      `${pathUrl}/content-repeater/${id}`
    )
    if (data) {
      commit('SET_CONTENT_REPEATER_OBJECT', data.data)
    }
    commit('SET_IS_LOADING', false)
  },
  setDeleteId({commit}, id) {
    commit('SET_DELETE_ID', id)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },
  async getContentRepeaters({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(
      `${pathUrl}/content-repeater/${id}/list`
    )
    if (data) {
      commit('SET_CONTENT_LIST', data.data.data)
    }
    commit('SET_IS_LOADING', false)
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  resetList({commit}) {
    commit('RESET_LIST')
  },
}

const mutations = {
  RESET_STATE(state) {
    state.contentRepeaterObject = defaultState().contentRepeaterObject
  },
  RESET_LIST(){
    state.contentRepeaterList = defaultState().contentRepeaterList
  },
  SET_CONTENT_LIST(state, cnt) {
    state.contentRepeaterList = cnt
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_DEPARTMENT_ID(state, id) {
    state.contentRepeaterObject.department_id = id
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_ORG_LIST(state, org) {
    let results = org.data.map(object => {
      return {text: object.name, value: object.codename}
    })
    state.orgObject = results
  },
  SET_CONTENT_REPEATER_OBJECT(state, pkg) {
    state.contentRepeaterObject = pkg
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_DELETE_ID(state, id) {
    state.deleteId = id
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
